.gradientText {
  background: linear-gradient(to right, #3b82f6, #8b5cf6);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.titleContainer {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
}

.titleWord {
  display: inline-block;
}

@keyframes strikethrough {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes fadeOut {
  to { opacity: 0; visibility: hidden; }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.strikethrough::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 3px;
  background-color: red;
  animation: strikethrough 1s forwards;
}

.replace {
  animation: fadeOut 0.5s forwards;
}

.replace::after {
  animation: fadeOut 0.5s forwards;
}

.replaceText {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  animation: fadeInDown 0.8s 2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  white-space: nowrap;
}